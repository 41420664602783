// auth-service.js
import { UserSession, AppConfig } from '@stacks/auth';
import { showConnect } from '@stacks/connect';

export class AuthService {
    constructor(router) {
        this.router = router;

        // Initialize app config with required permissions
        const appConfig = new AppConfig(
            ['store_write'], // permissions
            window.location.origin, // your app domain
            '/manifest.json', // path to manifest
            '/auth' // redirect URI for auth
        );

        // Initialize user session
        this.userSession = new UserSession({ appConfig });

        // Check for pending sign in
        if (this.userSession.isSignInPending()) {
            this.handlePendingSignIn();
        }
    }

    async handlePendingSignIn() {
        console.log('Handling pending sign in');
        try {
            const userData = await this.userSession.handlePendingSignIn();
            console.log('Pending sign in handled successfully:', userData);
            this.updateUserState(userData);
            return userData;
        } catch (error) {
            console.error('Failed to handle pending sign in:', error);
            throw error;
        }
    }

    initialize() {
        if (this.userSession.isUserSignedIn()) {
            const userData = this.userSession.loadUserData();
            return this.updateUserState(userData);
        }
        return null;
    }

    updateUserState(userData) {
        console.log('Updating user state with userData:', userData);
        const network = window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet';
        const currentAddress = userData.profile.stxAddress[network];
        console.log('Selected network:', network, 'Current address:', currentAddress);
        this.router.setCurrentStxAddress(currentAddress);
        return currentAddress;
    }

    isSignedIn() {
        return this.userSession?.isUserSignedIn() || false;
    }

    getCurrentAddress() {
        if (!this.isSignedIn()) return null;
        const userData = this.userSession.loadUserData();
        const network = window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet';
        return userData.profile.stxAddress[network];
    }

    async connectWallet(isChange = false) {
        console.log('Starting wallet connection. isChange:', isChange);

        // If changing account, sign out first
        if (isChange && this.isSignedIn()) {
            console.log('Signing out current session for account change');
            this.disconnectWallet();
        }

        if (this.userSession.isUserSignedIn() && !isChange) {
            console.log('User already signed in, loading existing session');
            const userData = this.userSession.loadUserData();
            return this.updateUserState(userData);
        }

        return new Promise((resolve, reject) => {
            console.log('Showing connect modal');

            let connectTimeout = setTimeout(() => {
                console.log('Connection attempt timed out');
                reject(new Error('Connection timed out'));
            }, 30000); // 30 second timeout

            showConnect({
                appDetails: {
                    name: 'GATED',
                    icon: window.location.origin + '/gated-logo.png',
                },
                redirectTo: '/auth',  // Remove window.location.origin
                onCancel: () => {
                    console.log('User cancelled connection');
                    clearTimeout(connectTimeout);
                    reject(new Error('User cancelled connection'));
                },
                onFinish: async () => {
                    console.log('Connection finished callback triggered');
                    try {
                        clearTimeout(connectTimeout);
                        // Add a small delay to allow the session to be properly established
                        await new Promise(resolve => setTimeout(resolve, 500));

                        if (!this.userSession.isUserSignedIn()) {
                            throw new Error('Session not established after connection');
                        }

                        const userData = this.userSession.loadUserData();
                        console.log('User data loaded:', userData);

                        const currentAddress = this.updateUserState(userData);
                        console.log('User state updated with address:', currentAddress);

                        resolve(currentAddress);
                    } catch (error) {
                        console.error('Error in auth completion:', error);
                        reject(error);
                    }
                },
                userSession: this.userSession,
                appConfig: {
                    manifestPath: '/manifest.json',
                    redirectPath: '/auth',
                    cookieOptions: {
                        domain: window.location.hostname.includes('localhost')
                            ? 'localhost'
                            : '.gated.so',
                    }
                }
            });
        });
    }

    disconnectWallet() {
        if (this.isSignedIn()) {
            this.userSession.signUserOut();
            this.router.setCurrentStxAddress(null);

            // If on subdomain, redirect to main domain
            const host = window.location.hostname;
            const isSubdomain = host.includes('.');

            if (isSubdomain) {
                const domain = host.includes('gated.so') ? 'gated.so' : 'localhost:3000';
                window.location.href = `${window.location.protocol}//${domain}`;
            } else {
                // If already on main domain, just refresh the page
                window.location.reload();
            }
        }
    }

    formatAccountNumber(address) {
        if (!address) return '';
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    }
}