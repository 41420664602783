// walletDropdown.js
class WalletDropdown {
    constructor(auth, router) {
        this.auth = auth;
        this.router = router;
        this.dropdownVisible = false;
    }

    render(container) {
        const isSignedIn = this.auth.isSignedIn();
        const currentAddress = this.auth.getCurrentAddress();

        const dropdownHTML = `
      <div class="wallet-dropdown">
        ${isSignedIn ? `
          <button id="wallet-dropdown-btn" class="wallet-btn">
            ${this.auth.formatAccountNumber(currentAddress)}
            <span class="chevron-down">▼</span>
          </button>
          <div id="wallet-dropdown-content" class="wallet-dropdown-content">
            <button id="change-account-btn">Change Account</button>
            <button id="sign-out-btn">Sign Out</button>
          </div>
        
        ` : `
          <button id="connect-wallet-btn" class="connect-btn">
            Connect Wallet
          </button>
        `}
      </div>
    `;

        // Add the styles
        this.addStyles();

        // Insert the HTML
        container.innerHTML = dropdownHTML;

        // Attach event listeners
        this.attachEventListeners();
    }

    addStyles() {
        const styles = `
      .wallet-dropdown {
        position: relative;
        display: inline-block;
      }

      .wallet-btn {
        padding: 10px 20px;
        background: #4A5568;
        color: white;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
      }

      .chevron-down {
        font-size: 10px;
        transition: transform 0.2s;
      }

      .wallet-dropdown-content {
        position: absolute;
        right: 0;
        top: 100%;
        margin-top: 8px;
        background: white;
        border: 1px solid #E2E8F0;
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
        min-width: 200px;
        display: none;
        z-index: 1000;
      }

      .wallet-dropdown-content.show {
        display: block;
      }

      .wallet-dropdown-content button {
        display: block;
        width: 100%;
        padding: 10px 16px;
        text-align: left;
        border: none;
        background: none;
        cursor: pointer;
        color: #4A5568;
        font-size: 14px;
      }

      .wallet-dropdown-content button:hover {
        background: #F7FAFC;
      }

      .connect-btn,
      .dashboard-btn {
        padding: 10px 20px;
        background: #4A5568;
        color: white;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        margin-top: 10px;
      }

      .connect-btn:hover,
      .dashboard-btn:hover,
      .wallet-btn:hover {
        background: #2D3748;
      }
    `;

        // Only add styles once
        if (!document.getElementById('wallet-dropdown-styles')) {
            const styleSheet = document.createElement('style');
            styleSheet.id = 'wallet-dropdown-styles';
            styleSheet.textContent = styles;
            document.head.appendChild(styleSheet);
        }
    }

    attachEventListeners() {
        const isSignedIn = this.auth.isSignedIn();

        if (isSignedIn) {
            const dropdownBtn = document.getElementById('wallet-dropdown-btn');
            const dropdownContent = document.getElementById('wallet-dropdown-content');
            const changeAccountBtn = document.getElementById('change-account-btn');
            const signOutBtn = document.getElementById('sign-out-btn');
            const dashboardBtn = document.getElementById('dashboard-btn');

            // Dropdown toggle
            dropdownBtn?.addEventListener('click', (e) => {
                e.stopPropagation();
                dropdownContent.classList.toggle('show');
            });

            // Change account
            changeAccountBtn?.addEventListener('click', () => {
                window.connectWallet(true);
                dropdownContent.classList.remove('show');
            });

            // Sign out
            signOutBtn?.addEventListener('click', () => {
                window.disconnectWallet();
                dropdownContent.classList.remove('show');
            });

            // Dashboard navigation
            dashboardBtn?.addEventListener('click', () => {
                const currentAddress = this.auth.getCurrentAddress();
                this.router.navigateToAdmin(currentAddress);
            });

            // Close dropdown when clicking outside
            document.addEventListener('click', (e) => {
                if (!e.target.matches('.wallet-btn')) {
                    dropdownContent?.classList.remove('show');
                }
            });
        } else {
            // Connect wallet
            const connectBtn = document.getElementById('connect-wallet-btn');
            connectBtn?.addEventListener('click', () => {
                window.connectWallet();
            });
        }
    }
}

export default WalletDropdown;